import React from "react"
import { Container, Row, Col } from 'reactstrap';
import { StaticQuery, graphql } from "gatsby"
import SekcjaGaleriaPod from "../components/sekcjagaleriapodobne";


export default function Header(props) {
  return (
    <StaticQuery
      query={ graphql`
      query awpqwep {
        allWordpressAcfPortfolio(filter: {acf: {lang: {eq: "en"}}}) {
          nodes {
            acf {
              lang
              slug
              kategoria
              title
              tekstmain
              imgmain {
                localFile {
                  url
                }
                acf {
                  alt_en
                }
              }
              imgfeatured {
                localFile {
                  url
                }
                acf {
                  alt_en
                }
              }
              tekst2title
              imgsecond {
                localFile {
                  url
                }
                acf {
                  alt_en
                }
              }
              images_title
              img_rep {
                img {
                  localFile {
                    url
                  }
                  acf {
                    alt_en
                  }
                }
              }
            }
          }
        }
      }
     
     
      `}
      render={data => (
        <div className="section4 podobne-changes">
           <SekcjaGaleriaPod lang={props.lang} gal={data.allWordpressAcfPortfolio.nodes}></SekcjaGaleriaPod>
        </div>
      )}
    />
  )
}
