import { Link } from "gatsby"
import React from "react"
import Filterizr from 'filterizr';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from 'reactstrap';

class SectionGalleryPod extends React.Component {
  componentDidMount() {


     const options = {
        animationDuration: 0.5, // in seconds
        callbacks: {
          onFilteringStart: function() { },
          onFilteringEnd: function() { },
          onShufflingStart: function() { },
          onShufflingEnd: function() { },
          onSortingStart: function() { },
          onSortingEnd: function() { }
       },
         delay: 100,
  delayMode: 'progressive',
  easing: 'ease-out',
       filter: 'all',
       layout: 'sameSize',
  gutterPixels: 0,
  filterOutCss: {
    opacity: 0,
    transform: 'scale(0.5)'
       },
  filterInCss: {
    opacity: 1,
    transform: 'scale(1)'
  },
      
      }

    const filterizr = new Filterizr(".filter-container", options);
  }

  excert(text){
    text = text.replace(/<\/?[^>]+(>|$)/g, "");
    text = text.substring(0,  50) + '...';
    return text;
    }

  clickonGallery(event) {
     [...document.getElementsByClassName("gallerytab")].forEach(
      (element, index, array) => {
       element.classList.remove("active");
      }
    );
     event.target.classList.toggle("active");
  }
  getShitContent(){
    let pierwsza = 0;
  const gals = this.props.gal.map((val, index) =>   {
      if(pierwsza < 3){
        pierwsza++;
      return (
        <Link to={"/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
          <ScrollAnimation animateOnce='true' animateIn='fadeIn' delay={index * 100}>
          <div className='gallery-img'>
        <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.alt_text}></img>
        </div>
        </ScrollAnimation></div></Link>
      )} 
    }
       );
       return gals;
  }

  getShitContenten(){
    let pierwsza = 0;
  const gals = this.props.gal.map((val, index) =>   {
      if(pierwsza < 3){
        pierwsza++;
      return (
        <Link to={"/en/portfolio/" + val.acf.slug}><div className="filtr-item boxik" data-category={val.acf.kategoria}>
          <ScrollAnimation animateOnce='true' animateIn='fadeIn' delay={index * 100}>
          <div className='gallery-img'>
        <img key={index} src={val.acf.imgfeatured.localFile.url} alt={val.acf.imgfeatured.acf.alt_en}></img>
        </div>
        </ScrollAnimation>
</div></Link>
      )} 
    }
       );
       return gals;
  }


    render() {
      
      return (
          <>
            <div className='gallery-head-row'>
            {this.props.lang === 'en' ? 
            <h2 className='title text-center'>OTHER PROJECTS<span className='dot'>.</span></h2>
            : 
            <h2 className='title text-center'>INNE PROJEKTY<span className='dot'>.</span></h2>
            }
            </div>
            <Container fluid={true}>
              <div className="filter-container">
              {this.props.lang === 'en' ? this.getShitContenten() :   this.getShitContent()}
              </div>  
              </Container>
          </>
      );
    }
  }
  


export default SectionGalleryPod;
