import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap';
import Podobne from "../components/sekcjapodobne"
import Podobneen from "../components/sekcjapodobneen"
import ScrollAnimation from 'react-animate-on-scroll';
import Helmet from "react-helmet"
const PortfolioPostTemplate = ({ data, pageContext }) => (
    <>
    {pageContext.lang === 'pl' ?
   
     <Helmet
  title='Strony internetowe Olsztyn | Sklepy internetowe Olsztyn | Aplikacje webowe Olsztyn | Lemoon Web'
  titleTemplate='Strony internetowe Olsztyn | Sklepy internetowe Olsztyn | Aplikacje webowe Olsztyn | Lemoon Web'
  meta={[
    {  
      name: `description`,
      content: 'Potrzebujesz strony, sklepu lub dedykowanej aplikacji webowej? Dzięki naszemu wieloletniemu doświadczeniu jesteśmy w stanie sprostać Twoim wymaganiom. Zapraszamy do naszego biura w Olsztynie, żeby przy kawie omówić Twój projekt.', 
    },
    {
      property: `og:title`,
      content: 'Strony internetowe Olsztyn | Sklepy internetowe Olsztyn | Aplikacje webowe Olsztyn | Lemoon Web',
    },
  ]}></Helmet>
   
    :
    <Helmet
    title='Web Development | Websites : Lemoon Web'
    titleTemplate='Web Development | Websites : Lemoon Web'
    meta={[
      {  
        name: `description`,
        content: 'We are happy to create websites, stores and dedicated web applications, as well as advertising graphics. Get in touch today.', 
      },
      {
        property: `og:title`,
        content: 'Web Development | Websites : Lemoon Web',
      },
    ]}></Helmet>
    
    
    }
  <Layout lang={pageContext.lang} pageInfo={{ pageName: "portfoliopost" }} >
        <div className="small-banner-content">
  <div style={{backgroundImage: `url('` + data.wordpressAcfPortfolio.acf.imgmain.localFile.url + `')`, backgroundPosition: `center`}} className="background-small"></div>
        <div className="text-on-banner">  
        <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
        <h2 className='smallbanner-title'>{data.wordpressAcfPortfolio.acf.title}</h2>
        </ScrollAnimation>
      </div>
      <div className='right-bottom-curve-cont'>
      <ScrollAnimation animateOnce='true' animateIn='fadeInRight' delay='200' duration='0.5'>
        <div className="right-bottom-curve"></div>
      </ScrollAnimation>
      </div>
      </div>


<div className='portfoliowrapper'>
<Container className='portfolio-post'>
  <Row className='portfolio-row'>
    <Col md='4'>
      <div className='inner-text'>
        <h1 className='title'>{data.wordpressAcfPortfolio.acf.tekst2title}</h1>
        <p className='text'>{data.wordpressAcfPortfolio.acf.tekstmain}</p>
      </div>
    </Col> 
    <Col md='8' className='image-order'>
    {pageContext.lang === 'pl' ?
      <div className='image-box'>
        <img src={data.wordpressAcfPortfolio.acf.imgsecond.localFile.url} alt={data.wordpressAcfPortfolio.acf.imgsecond.alt_text}></img>
      </div>
      :
      <div className='image-box'>
        <img src={data.wordpressAcfPortfolio.acf.imgsecond.localFile.url} alt={data.wordpressAcfPortfolio.acf.imgsecond.acf.alt_en}></img>
      </div>
      }
    </Col>
  </Row>
  <Row>
    <Col>
      <div className='inner-text'>
          <h2 className='title single-photos-title'>{data.wordpressAcfPortfolio.acf.images_title}</h2>
      </div>
      <div className='single-photos-list'>
        {data.wordpressAcfPortfolio.acf.img_rep.map((val, index) =>
        <div className='image-box single-photo'>
          {pageContext.lang === 'pl' ?
        <img key={index} src={val.img.localFile.url} alt={val.img.alt_text}></img>
        :
        <img key={index} src={val.img.localFile.url} alt={val.img.acf.alt_en}></img>
        }
        </div>
        )}
      </div>
      
    </Col>
  </Row>

</Container>

{pageContext.lang === 'pl' ? 
<Podobne/>
: <Podobneen lang="en"/>}

   
  </div> 
  </Layout>
  </>
) 
export default PortfolioPostTemplate
export const query = graphql`
  query($id: Int!) {
    wordpressAcfPortfolio(wordpress_id: { eq: $id }) {
      acf {
        lang
        slug
        title
        tekstmain
        imgmain {
          localFile {
            url
          }
          alt_text
          acf {
            alt_en
          }
        }
        tekst2title
        imgsecond {
          localFile {
            url
          }
          alt_text
          acf {
            alt_en
          }
        }
        images_title
        img_rep {
          img {
            localFile {
              url
            }
            alt_text
            acf {
              alt_en
            }
          }
        }
      }
    }
    wordpressPage(title: {eq: "Blog"}) {
      acf {
        blog_banner_img {
          localFile {
            url
          }
          alt_text
          acf {
            alt_en
          }
        }
      }
    }
  }
`